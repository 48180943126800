<template>
  <main>
    <div id="frame">
      <h1>Passwort zurücksetzen</h1>
      <p class="label">altes Passwort:</p>
      <input class="parallelogramm" type="password" v-model="oldPassword" />
      <p class="label">neues Passwort:</p>
      <input class="parallelogramm" type="password" v-model="newPasswordI" />
      <p class="label">neues Passwort wiederholen:</p>
      <input class="parallelogramm" type="password" v-model="newPasswordII" v-on:keyup.enter="reset" />
      <div class="parallelogramm submit" v-on:click="reset">Passwort zurücksetzen</div>
    </div>
  </main>
</template>

<script>
import sha256 from 'crypto-js/sha256'
import axios from 'axios'
import globalFunctions from '../scripts/globalFunctions'

export default {
  name: 'Einstellungen',
  mixins: [globalFunctions],

  components: {

  },

  data () {
    return {
      oldPassword: '',
      newPasswordI: '',
      newPasswordII: ''
    }
  },

  methods: {
    reset: function () {
      if (this.newPasswordI === this.newPasswordII) {
        if (this.newPasswordI.length >= 6) {
          var oldHash = sha256(this.oldPassword)
          var newHash = sha256(this.newPasswordI)
          axios.post('https://mitgliederverwaltung.julia-sachsen.de/functions.php', {
            function: 'resetPassword',
            username: localStorage.username,
            oldPassword: oldHash,
            newPassword: newHash
          }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }).then(response => {
            if (response.data.length === 0) {
              localStorage.password = newHash
              alert('Passwort erfolgreich geändert.')
              this.$router.go(-1)
            } else {
              console.log(response)
              alert('Altes Passwort stimmt nicht.')
            }
          })
        } else {
          alert('Das Passwort muss mindestens 6 Zeichen lang sein.')
        }
      } else {
        alert('Passwörter stimmen nicht überein.')
      }
    }
  },

  computed: {

  },

  mounted: function () {

  }
}

</script>

<style scoped>
main {
  height: 100vh;
  width: 100vw;
  overflow: auto;
  margin-top: 0;
}

#frame {
  width: 60%;
  padding: 5%;
  padding-top: min(5%, 50px);
  margin: min(10%, 100px) 15% 2rem 15%;
  background: var(--gelb);
}

h1 {
  margin: 1em 0 0 0;
}

h1:first-of-type {
  margin: 0
}

.parallelogramm {
  transform: skewX(-15deg);
  width: calc(100% - 2 * min(2.5%, 20px));
  font-size: 1rem;
  padding: min(2.5%, 20px);
}

input {
  border: 0;
  color: var(--blau);
}

p.label {
  font-family: 'Anybody ExtraBold';
}

div.submit {
  text-transform: uppercase;
  text-align: center;
  margin-top: 1.5rem;
  background: var(--blau);
  color: var(--weiß);
  font-family: 'Anybody ExtraBold';
  cursor: pointer;
}
</style>
