import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

createApp(App).use(router).mount('#app')

var logoutTime = 30
if ((!localStorage.activity || localStorage.activity < Date.now() - logoutTime * 60 * 1000) && localStorage.password) {
  localStorage.removeItem('password')
  window.location.reload()
}

var element = document.getElementsByTagName('body')[0]
element.addEventListener('mousemove', function (e) { localStorage.activity = Date.now() })
element.addEventListener('keypress', function (e) { localStorage.activity = Date.now() })
setInterval(function () {
  if ((!localStorage.activity || localStorage.activity < Date.now() - logoutTime * 60 * 1000) && localStorage.password) {
    localStorage.removeItem('password')
    document.body.innerHTML = ''
    alert('Du wurdest wegen Inaktivität ausgeloggt.')
    window.location.reload()
  }
}, 60 * 1000)
