export default {
  methods: {
    logout: function () {
      localStorage.removeItem('password')
      this.$router.replace('Login')
    },

    Verband: function () {
      return parseInt(localStorage.Verband)
    },

    Zugriffsrechte: function () {
      return parseInt(localStorage.Zugriffsrechte)
    },

    go: function (Ziel) {
      switch (Ziel) {
        case 'Mitglieder':
          this.$router.push('/mitglieder')
          break
        case 'MitgliederDD':
          this.$router.push('/mitglieder/DD')
          break
        case 'MitgliederL':
          this.$router.push('/mitglieder/L')
          break
        case 'MitgliederCH':
          this.$router.push('/mitglieder/CH')
          break
        case 'Umlage':
          this.$router.push('/umlage')
          break
        case 'Anträge':
          this.$router.push('/todo#Antraege')
          break
        case 'Interessenten':
          this.$router.push('/todo#Interessenten')
          break
        case 'Einstellungen':
          this.$router.push('/einstellungen')
          break
      }
    },

    Intervalle: function (month) {
      switch (month) {
        case 0:
          return 'siehe Bemerkungen'
        case 1:
          return 'monatlich'
        case 3:
          return 'quartalsweise'
        case 6:
          return 'halbjahrlich'
        case 12:
          return 'jährlich'
      }
      return ''
    },

    Beitragskategorien: function (kategorie) {
      switch (kategorie) {
        case 0:
          return 'Schüler'
        case 1:
          return 'Azubis/Studenten'
        case 2:
          return 'Wehrdienst/FSJ/Arbeitslose'
        case 3:
          return 'Berufstätige'
      }
      return ''
    },

    WechselStatus: function (status) {
      switch (status) {
        case 0:
          return 'kein Wechsel'
        case 1:
          return 'möchte weg wechseln'
        case 2:
          return 'hat weg gewechselt'
        case 3:
          return 'möchte her wechseln'
        case 4:
          return 'hat her gewechselt'
      }
      return ''
    }
  }
}
