<template>
  <main>
    <h1>Umlage</h1>
    <Umlagefeld v-for="Umlage in Umlagen" :rechnungProp="Umlage.RechnungGestellt > 0" :bezahltProp="Umlage.bezahlt > 0" :Datum="Umlage.Datum" :KV="parseInt(Umlage.Kreisverband)" :Betrag0="parseFloat(Umlage.BetragK0)" :Betrag1="parseFloat(Umlage.BetragK1)" :Betrag2="parseFloat(Umlage.BetragK2)" :Betrag3="parseFloat(Umlage.BetragK3)" :Anzahl0="parseInt(Umlage.AnzahlK0)" :Anzahl1="parseInt(Umlage.AnzahlK1)" :Anzahl2="parseInt(Umlage.AnzahlK2)" :Anzahl3="parseInt(Umlage.AnzahlK3)" />
  </main>
</template>

<script>
import Umlagefeld from '../components/Umlagefeld'

import axios from 'axios'

export default {
  name: 'Umlage',

  components: {
    Umlagefeld
  },

  data () {
    return {
      Umlagen: []
    }
  },

  methods: {
    getData: function () {
      axios.post('https://mitgliederverwaltung.julia-sachsen.de/functions.php', {
        function: 'getUmlage',
        username: localStorage.username,
        password: localStorage.password
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        this.Umlagen = response.data
      })
    }
  },

  computed: {

  },

  mounted: function () {
    this.getData()
    setInterval(this.getData, 2 * 60 * 1000)
  }
}

</script>

<style scoped>

</style>
