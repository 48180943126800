<template>
  <main>
    <div id="frame">
      <h1>Login</h1>
      <p class="label">Nutzername:</p>
      <input ref="user" v-model="username" class="parallelogramm" type="text" />
      <p class="label">Passwort:</p>
      <input ref="pass" v-model="password" class="parallelogramm" type="password" v-on:keyup.enter="login" />
      <div v-on:click="login" class="parallelogramm submit">Einloggen</div>
    </div>
  </main>
</template>

<script>
import axios from 'axios'
import sha256 from 'crypto-js/sha256'

export default {
  name: 'Login',

  components: {

  },

  data () {
    return {
      username: '',
      password: ''
    }
  },

  methods: {
    login: function () {
      if (this.username.length >= 3 && this.password.length >= 3) {
        var hash = sha256(this.password)
        axios.post('https://mitgliederverwaltung.julia-sachsen.de/functions.php', {
          function: 'login',
          username: this.username,
          password: hash
        }, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then(response => {
          if (response.data === 'Falsche Zugangsdaten') {
            alert('Zugangsdaten falsch.')
          } else if (response.data === 'Wartung') {
            alert('Wartungspause')
          } else {
            localStorage.username = this.username
            localStorage.password = hash
            localStorage.Verband = response.data.Verband
            localStorage.Zugriffsrechte = response.data.Zugriffsrechte
            this.$router.go('Dashboard')
          }
        })
      }
    }
  },

  computed: {

  },

  mounted: function () {
    if (localStorage.username != null) {
      this.username = localStorage.username
      this.$refs.pass.focus()
    } else {
      this.$refs.user.focus()
    }
  }
}

</script>

<style scoped>
main {
  height: 100vh;
  width: 100vw;
  overflow: auto;
  margin-top: 0;
}

#frame {
  width: 60%;
  padding: 5%;
  padding-top: min(5%,50px);
  margin: min(10%,100px) 15% 2rem 15%;
  background: var(--magentaHell);
  border-radius: 1em;
}

h1 {
  margin: 0;
}

p.label {
  font-family: 'Anybody ExtraBold';
}

input {
  border: 0;
  color: var(--blau);
}

.parallelogramm {
  /* transform: skewX(-15deg); */
  width: calc(100% - min(2.5%, 20px)*2);
  font-size: 1rem;
  padding: min(2.5%,20px);
  border-radius: 1em;
}

div.submit {
  text-transform: uppercase;
  text-align: center;
  margin-top: 1.5rem;
  background: var(--magenta);
  color: var(--weiß);
  font-family: Anybody ExtraBold;
  cursor: pointer;
}
</style>
