<template>
  <main>
    <Tabelle titel="Fördermitglieder" tableFunction="getFördermitglieder" :KV="$route.params.KV" :showCopy="true" />
  </main>
</template>

<script>
import Tabelle from '../components/Tabelle'

export default {
  name: 'Fördermitglieder',

  components: {
    Tabelle
  },

  data () {
    return {

    }
  },

  methods: {

  },

  computed: {

  },

  mounted: function () {

  }
}

</script>

<style scoped>

</style>
